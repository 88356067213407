import React, { useContext, useState, useEffect } from 'react'
import CartCtx from '../CartCtx'
import { isIterableArray } from '../block-slider/utils';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputNumber from 'rc-input-number';

const CartItem = ({ isControl, removeProductCart, product, index }) => {

    const { cart, selectedCenter, decreaseProduct, increaseProduct } = useContext(CartCtx)

    const cartProduct = cart[index]
    const count_cart = cartProduct?.count_cart || 1

    const [productState, setProductState] = useState(product);
    const [productPrice, setProductPrice] = useState({
        price: product.price,
        priceBadgeStock: product.priceBadgeStock
    }); // цена у выбранного тц

    const [productStatus, setProductStatus] = useState(null);

    useEffect(() => {
        setProductState(product)
    }, [product]);

    // если выбран тц - меняем цену 
    useEffect(() => {
        if (selectedCenter?.id) {
            if (isIterableArray(productState.tc)) {

                const selectedPrice = productState.tc.find(item => item.id == selectedCenter.id)

                const selectedStatus = isIterableArray(productState.count_center_status) ? productState.count_center_status.find(item => item.center_id == selectedCenter.id) : false
                // const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.tc_id == selectedCenter.id) : false
                if (selectedStatus?.center_id) setProductStatus(selectedStatus)
                else setProductStatus(null)

                if (selectedPrice) {
                    setProductPrice({
                        price: selectedPrice.price,
                        priceBadgeStock: selectedPrice.priceBadgeStock
                    })
                }
            }
        }
        else if (productState) {
            setProductPrice({
                price: productState.price,
                priceBadgeStock: productState.priceBadgeStock
            })
            const selectedStatus = isIterableArray(productState.count_center_status) ? productState.count_center_status.find(item => item.center_id === 99) : false
            // const selectedStatus = isIterableArray(productState.status) ? productState.status.find(item => item.defaultTc === true) : false
            if (selectedStatus?.center_id) setProductStatus(selectedStatus)
            else setProductStatus(null)
        }
    }, [selectedCenter?.id, productState]);


    const onChange = (e, id, count, articule) => {
        // console.log(e, id, count, articule)
        if (e < count) decreaseProduct(id)
        if (e > count) increaseProduct(id)
    }
    // console.log(productState, productStatus)
    return (
        <div>
            <div className="cart-item">
                <div className="products-image-cart">
                    <Link className="products-image-cart__link" to={`/product/${productState.name_slug}`}>
                        <img
                            src={process.env.REACT_APP_BACKEND + 'uploads/products/main/' + productState.photo_url}
                            alt={("Изображение товара" + productState.name)} />
                    </Link>
                </div>
                <div className="products-info">
                    <div className="products-info__top">
                        <Link className="products-title" to={`/product/${productState.name_slug}`} title={productState.name}>
                            {productState.name}
                        </Link>
                        {
                            removeProductCart &&
                            <div className="products-remove">
                                <Button variant="link" className="products-remove__btn" onClick={() => removeProductCart(productState.id)}>
                                    <i className="i-remove" style={{ fontSize: 16 }} />
                                </Button>
                            </div>
                        }
                    </div>

                    <div className="products-info__bottom">

                        <div className="products-price product-sale">
                            <div className="products-price__wrap">
                                {
                                    productPrice.priceBadgeStock.price.discountPrice &&
                                    <div className="products-price__old">
                                        <span className="price i-rub">
                                            {productPrice.priceBadgeStock.price.price.toLocaleString('ru')}
                                        </span>

                                    </div>
                                }
                                <div className="products-price__main">
                                    {productPrice.priceBadgeStock.price.discountPrice &&
                                        <span className="price i-rub">
                                            {productPrice.priceBadgeStock.price.discountPrice.toLocaleString('ru')}
                                        </span>
                                    }
                                    {!productPrice.priceBadgeStock.price.discountPrice &&
                                        <span className="price i-rub" style={{ color: '#333' }}>
                                            {productPrice.priceBadgeStock.price.price.toLocaleString('ru')}
                                        </span>
                                    }
                                    {
                                        productState.unit ?
                                            <div className='price-unit'>
                                                <span>
                                                    /
                                                </span>
                                                <span>
                                                    {productState.unit}
                                                </span>
                                            </div>
                                            :
                                            '/шт'
                                    }
                                </div>


                            </div>
                        </div>
                        {
                            isControl ?
                                <div className='d-flex flex-column align-items-center'>
                                    {
                                        !productStatus?.cardActive && <p dangerouslySetInnerHTML={{ __html: productStatus?.name_card }} className='mb-1'></p>
                                    }
                                    {
                                        (count_cart > productStatus?.count_tc) ? <span className='text-muted my-1'>{'Товар не в наличии'}</span> : ''
                                    }
                                    <div className="products-quantity">
                                        <InputNumber
                                            key={index}
                                            min={0}
                                            max={10000}
                                            style={{ width: 100 }}
                                            value={count_cart}
                                            onChange={(e) => onChange(e, productState.id, count_cart)}
                                            readOnly={false}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                                :
                                <div className='d-flex align-items-baseline' style={{ lineHeight: '26px' }}>
                                    {count_cart}
                                    {
                                        productState.unit ?
                                            <div className='price-unit' style={
                                                productPrice.priceBadgeStock.price.discountPrice ?
                                                    {
                                                        order: 3,
                                                        fontSize: 20,
                                                        color: 'rgba(10, 26, 92, 0.4)',
                                                        lineHeight: 1.4
                                                    }
                                                    :
                                                    {
                                                        lineHeight: 1.4
                                                    }
                                            }>
                                                <span>
                                                    {productState.unit}
                                                </span>
                                            </div>
                                            :
                                            ' шт'
                                    }
                                </div>
                        }
                        <div className="products-sum__total">
                            {productPrice.priceBadgeStock.price.discountPrice &&
                                <span className="price i-rub">
                                    {(productPrice.priceBadgeStock.price.discountPrice * count_cart).toLocaleString('ru')}
                                </span>
                            }
                            {!productPrice.priceBadgeStock.price.discountPrice &&
                                <span className="price i-rub" style={{ color: '#333' }}>
                                    {(productPrice.priceBadgeStock.price.price * count_cart).toLocaleString('ru')}
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CartItem