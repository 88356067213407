
// React 
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
// import { Link } from 'react-router-dom';



class PrivPolicyModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showCart: false
        }
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
    }

    handleClose() {
        this.setState({
            showCart: false
        })
    }
    handleShow() {
        this.setState({
            showCart: true
        })
    }

    render() {

        return (
            <>
                <div className="check-accept-order">
                    <div className="checkbox-filter__item">
                        <input className="custom-checkbox filter-check brand-check" type="checkbox" onChange={this.props.ppConfirm} id="m6" />
                        <label htmlFor="m6">
                            <p className='d-flex flex-wrap align-items-center'>
                                Подтверждая заказ, я принимаю условия
                                <Button variant="link" onClick={this.handleShow}>
                                    пользовательского соглашения
                                </Button>
                            </p>
                        </label>
                    </div>


                </div>
                <Modal
                    show={this.state.showCart}
                    onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    dialogClassName="modal-cart"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Пользовательское соглашение</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="content-text">
                            <p>Я согласен с условиями политики конфиденциальности и разрешаю использовать мои персональные данные компанией ООО «Галактика» на законных основаниях.</p>
                            <h5>Обработка персональных данных</h5>
                            <p>ООО «Галактика» собирает, обрабатывает, использует и хранит информацию, которая предоставляется Покупателем/Пользователем в разделе сайта (оформление заказа) с целью использования и  выполнения общих условий приобретения товаров и услуг на сайте galaktika.me сети торговых центров «Галактика».
                                Персональные данные обрабатываются ООО «Галактика» исключительно в понимании Закона Донецкой Народной Республики «О персональных данных». Такие данные, в соответствии с требованиями законодательства, регулирующего правоотношения, сохраняются, обрабатываются и используются в рамках всех правоотношений, возникающих между Вами и ООО «Галактика». Действия по обработке персональных данных включают сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление и уничтожение.
                                ООО «Галактика», берет на себя обязательства не разглашать полученную от покупателя информацию. Покупатель имеет право запросить, изменить или удалить свои персональные данные. Для этого Покупателю необходимо заявить требование, направив его на соответствующий контактный адрес, или позвонив по телефону, указанному на сайте galaktika.me</p>
                            <h5>Соглашение</h5>
                            <p>Предоставляя свои персональные данные на сайт ООО «Галактика» при оформлении Заказа, Вы предоставляете свое добровольное согласие на обработку, использование (в том числе и передачу) своих персональных данных, а также совершение иных действий, предусмотренных Законом Донецкой Народной Республики «О персональных данных», без ограничения срока действия такого соглашения. Также предоставляете полное соглашение на использование своих данных, для поддержания связи с Вами любым способом, включая звонки на указанный мобильный телефон посредством использования телефонной связи, отправку сообщений на указанный мобильный телефон, включая но не ограничиваясь, СМС-сообщений, сообщений посредством WhatsApp, Viber и т.п. с целью направления, в том числе, информационных сообщений, оповещения о проводимых акциях, информирования о поступлении новых товаров/услуг, рекламных и информационных рассылок, о проведении мероприятий и предлагаемых скидках.</p>
                            <h5>Сбор информации</h5>
                            <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области персональных данных. </p>
                            <ol>
                                <li>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц. </li>
                                <li>Персональные данные Пользователя/Покупателя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
                                    <ol>
                                        <li>Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц. </li>
                                        <li>Персональные данные Пользователя/Покупателя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.</li>
                                    </ol>
                                </li>
                            </ol>
                            <h5>Акции</h5>
                            <p>ООО «Галактика» регулярно проводит рекламные Акции, направленные на привлечение покупателей и увеличение спроса на товары. Принимая участие в Акции, Вы предоставляете однозначное согласие на бесплатное использование вашего имени, фамилии, фотографии, интервью или других материалов о Вас в рекламных целях, в том числе право публикации вашего ФИО имени и фотографии в средствах массовой информации, любых печатных, аудио - и видеоматериалах, интервью со СМИ. Такое использование не компенсируется (не оплачивается).</p>

                            <h5>ОФЕРТА</h5>
                            <ol>
                                <li>
                                    <b>Общие положения</b>
                                    <ol>
                                        <li>Настоящая публичная оферта (далее - Договор) является официальным предложением ООО «Галактика» заключить Договор купли-продажи Товара, представленного на сайте galaktika.me на условиях, определенных в настоящем Договоре.</li>
                                        <li>Договор является публичным, его условия одинаковы для всех покупателей (физических лиц) независимо от статуса.</li>
                                        <li>Путем заключения данного Договора Покупатель подтверждает, что полностью ознакомлен и согласен с условиями настоящего Договора, в том числе: с условиями оформления заказа, оплаты Товара, доставки Товара, а также ответственности за недобросовестный заказ.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Термины и определения</b>
                                    <ol>
                                        <li><b>Продавец</b> – торговая сеть ООО «Галактика», идентификационный код 50008897, действующая на основании Свидетельства о государственной регистрации юридического лица серия АА03 № 04953 от 05.06.2015г., учрежденная в соответствии с законодательством ДНР и включающая в себя четыре необособленных подразделения:
                                            <ul>
                                                <li>«Торговый центр «ГАЛАКТИКА» №1 г.Макеевка», расположенный по адресу: г. Макеевка, Центрально-Городской район, ул. 250–летия Донбасса, 74;</li>
                                                <li>«Торговый центр «ГАЛАКТИКА» №2 г.Донецк», расположенный по адресу: г. Донецк, ул.Шутова, 37;</li>
                                                <li>«Торговый центр «ГАЛАКТИКА» №3 г.Горловка», расположенный по адресу: г. Горловка, ул.Маршала Жукова, 7;</li>
                                                <li>«Торговый центр «ГАЛАКТИКА» №4 г.Донецк», расположенный по адресу: г. Донецк, ул.Соколиная. 38.</li>
                                            </ul>
                                        </li>
                                        <li><b>Покупатель</b> – дееспособное физическое лицо, достигшее 18-летнего возраста, акцептовавшее условия данной публичной оферты на нижеуказанных условиях и предоставившее Продавцу свои персональные данные посредством подтверждения Заказа.</li>
                                        <li><b>Товар </b> – предмет Договора сторон, который был выбран покупателем на сайте ООО «Галактика» и помещен в «Корзину».</li>
                                        <li><b>Сайт</b> – официальная веб-страница Продавца galaktika.me, созданная для заключения договоров купли-продажи на основании ознакомления Покупателя с предложенным Продавцом описанием Товара с помощью сети Интернет.</li>
                                        <li><b>Заказ</b> – должным образом оформленная Покупателем электронная форма на приобретение и доставку по указанному Покупателем адресу, выбранных на сайте Товаров.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Предмет оферты</b>
                                    <ol>
                                        <li>Продавец обязуется передать в собственность Покупателю Товар, а Покупатель обязуется оплатить и принять Товар на условиях настоящего Договора.</li>
                                        <li>Датой заключения Договора-оферты (акцептом оферты) и моментом полного и безоговорочного принятия Покупателем условий Договора считается дата заполнения и подтверждения Покупателем электронной формы заказа, размещенной на сайте.</li>
                                        <li>Товар принадлежит Продавцу на праве собственности, не находится под залогом, под арестом, не является предметом притязания третьих лиц.</li>
                                        <li>Право собственности на заказанные Товары переходит к Покупателю с момента фактической передачи товара Покупателю и оплаты последним полной стоимости товара. Риск случайной гибели или повреждения Товара переходит к Покупателю с момента фактической передачи товара Покупателю.</li>
                                        <li>Данный Договор распространяется на все виды Товаров, представленных на Сайте, пока такие предложения с отметкой в наличии присутствуют в каталоге сайта.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Регистрация на сайте</b>
                                    <ol>
                                        <li>Для оформления Заказа необходимо предоставить следующую информацию: ФИО, номер телефона, адрес Доставки Товара.</li>
                                        <li>Продавец не несет ответственности за достоверность информации, предоставляемой Покупателем при оформлении заказа.</li>
                                        <li>Общение Покупателя с представителями Продавца должно строиться на принципах общепринятых норм морали и коммуникационного этикета. Запрещено использование нецензурных слов, оскорбительных выражений, а также угроз и шантажа, в независимости от того, в каком виде и кому они были адресованы.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Стоимость Товара</b>
                                    <ol>
                                        <li>Цены на товар определяются Продавцом в одностороннем бесспорном порядке и указываются на страницах Сайта, расположенного по интернет-адресу: galaktika.me Цены на Товары и услуги могут изменяться Продавцом в одностороннем порядке в зависимости от конъюнктуры рынка. При этом цена отдельной единицы Товара, стоимость которой оплачена Покупателем в полном объеме, не может быть изменена Продавцом в одностороннем порядке.</li>
                                        <li>Цена товара указывается в российских рублях.</li>
                                        <li>Оплата за Товар и услуги Доставки производится Покупателем в кассах ТЦ.  Продавец обязан выдать потребителю расчетный документ установленной формы, удостоверяющий факт покупки, с отметкой о дате продажи.</li>
                                        <li>До момента оплаты Товар не резервируется, Продавец не может гарантировать доступность Товара в количестве, указанном в момент оформления Заказа, в результате чего могут увеличиться сроки обработки Заказа.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Доставка Товаров / Самовывоз</b>
                                    <ol>
                                        <li>Доставка товара Покупателю осуществляется в сроки, согласованные Сторонами при подтверждении Заказа, с учетом режима работы Продавца.</li>
                                        <li>Задержки в доставке Товаров возможны ввиду непредвиденных обстоятельств.</li>
                                        <li>Стоимость доставки Товара определяется с учетом срочности Заказа, зоны доставки. При составлении маршрута и расчете километража используется онлайн версия программы «2ГИС», включающая в себя линейку для измерения расстояний.</li>
                                        <li>Доставка Товаров осуществляется по фактическому адресу, указанному Покупателем при оформлении Заказа на сайте и/или по телефону Продавца.</li>
                                        <li>При доставке Товара Покупатель ставит свою подпись в Акте приема-передачи Товара. После получения Заказа претензии к внешним дефектам товара, его количеству, комплектности и товарному виду не принимаются.</li>
                                        <li>Вы можете выбрать товар на сайте, и самостоятельно забрать и оплатить его в пункте выдачи того торгового центра, который Вы указали при осуществлении заказа на сайте ТЦ «Галактика».</li>
                                        <li>Пункты выдачи заказов расположены в каждом из Торговых центров «Галактика».</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Права и обязанности сторон</b>
                                    <ol>
                                        <li>Продавец обязуется:
                                            <ul>
                                                <li>Передать Покупателю Товар в соответствии с условиями этого Договора и Заказом Покупателя. .</li>
                                                <li>Не разглашать любую частную информацию о Покупателе и не предоставлять доступ к этой информации третьим лицам, за исключением случаев, предусмотренных законодательством или по желанию самого Покупателя.</li>
                                            </ul>
                                        </li>
                                        <li>Продавец имеет право:
                                            <ul>
                                                <li>Изменять условия настоящего Договора, а также цены на Товары и услуги в одностороннем порядке, размещая их на сайте Продавца. Все изменения вступают в силу с момента их публикации.</li>
                                                <li>Продавец имеет право осуществлять посредством использования телефонной связи, отправку сообщений на указанный мобильный телефон, включая, но не ограничиваясь, СМС-сообщений, сообщений посредством WhatsApp, Viber и т.п., отправку сообщений в социальных сетях с его согласия. Покупатель вправе отказаться от получения рекламной и другой информации без объяснения причин отказа. Сервисные сообщения, информирующие Покупателя о заказе и этапах его обработки, отправляются автоматически и не могут быть отклонены Покупателем.</li>
                                            </ul>
                                        </li>
                                        <li>Покупатель обязуется:
                                            <ul>
                                                <li>К моменту заключения Договора ознакомиться с содержанием и условиями Договора, ценами, предлагаемыми Продавцом на сайте Продавца.</li>
                                                <li>Для выполнения Продавцом своих обязательств перед Покупателем последний должен сообщить все необходимые данные, однозначно идентифицирующие его как Покупателя, и достаточные для доставки Покупателю заказанного Товара.</li>
                                                <li>Оплатить стоимость Товара и принять заказанный Товар, оплатить услугу Доставки. Внешний вид Товара, а также комплектность всего Заказа должны быть проверены Получателем в момент получения Товара.</li>
                                                <li>Покупатель обязуется не использовать сайт Продавца для рекламы или иного стимулирования сбыта любых товаров и услуг в любой форме, включая, стимулирование Покупателей к использованию других ресурсов, являющихся конкурентом Продавца.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Прочие условия</b>
                                    <ol>
                                        <li>Продавец обеспечивает наличие на своем складе Товаров, представленных на сайте. Количество Товара ограничено. Сопровождающие Товар фотографии могут отличаться от фактического внешнего вида Товара, учитывая особенности цветовой передачи различных устройств, с помощью которых просматривается сайт.</li>
                                        <li>Сопровождающие Товар описания/характеристики не являются исчерпывающей информацией и могут содержать опечатки. В соответствии со ст.15 Закона ДНР «О защите прав потребителей» №53-IНС от 05.06.2015г. получить полную доступную и достоверную информацию о товаре можно по телефонам указанным на сайте ТЦ «Галактика», или в Торговом центре, где будет приобретаться Товар.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Условия возврата Товара</b>
                                    <ol>
                                        <li>Покупатель имеет право на возврат Продавцу непродовольственного Товара надлежащего качества, если он не удовлетворил его по форме, габаритам, фасону, цвету, размеру или по другим причинам не может быть использован им по назначению. Покупатель имеет право на возврат Товара надлежащего качества в течение 14 (четырнадцати) дней, не считая дня покупки. Возврат Товара надлежащего качества производится, если он не использовался и сохранен его товарный вид, потребительские свойства, пломбы, ярлыки, а также расчетный документ, выданный потребителю вместе с проданным Товаром. Перечень Товаров, не подлежащих обмену (возврату) на основаниях, предусмотренных в настоящем пункте, утвержден Постановлением Совета Министров Донецкой Народной Республики от 31.05.2016г. № 7-22.</li>
                                        <li>Возврат Покупателю стоимости Товара надлежащего качества производится в течение 7 (семи) дней с момента его получения Продавцом при условии соблюдения требований, предусмотренных п. 9.1. Договора. Доставка Товара надлежащего качества по адресу Продавца осуществляется за счет Покупателя и Продавцом не возмещается.</li>
                                        <li>В случае выявления на протяжении установленного гарантийного срока недостатков в Товаре, Покупатель в порядке и в сроки, имеет право предъявить Продавцу требования, предусмотренные действующим законодательством ДНР. Продавец имеет право в данном случае осуществить проверку качества – в течение четырнадцати дней или по договоренности сторон в более продолжительный срок.</li>
                                        <li>В случае выявления на протяжении установленного гарантийного срока недостатков в Товаре, Покупатель в порядке и в сроки, имеет право предъявить Продавцу требования, предусмотренные действующим законодательством ДНР. Продавец имеет право в данном случае осуществить проверку качества – в течение четырнадцати дней или по договоренности сторон в более продолжительный срок.</li>
                                        <li>В случае, если недостатки товара возникли по вине производителя, то при предъявлении Покупателем требования о безвозмездном устранении недостатков они должны быть устранены в течение 14 -ти дней с даты его предъявления или в другой срок по согласованию сторон. Срок гарантийного ремонта может превышать 14 календарных дней, но не более 45 календарных дней, в случае необходимости замены комплектующих или отправки товара в специализированный сервисный центр, находящийся за пределами ДНР.</li>
                                        <li>Продавец не отвечает за недостатки Товара, возникшие после его передачи Покупателю вследствие нарушения Покупателем правил пользования или хранения Товара, действий третьих лиц или обстоятельств непреодолимой силы.</li>
                                        <li>В случае, если недостатки возникли по вине Покупателя, то требования Покупателя о возврате товара, замены товара на аналогичный или возмещение расходов на устранение недостатка, остаются без удовлетворения.</li>
                                        <li>Рассмотрение требований, предусмотренных Законом ДНР «О защите прав потребителей», осуществляется Продавцом при условии предоставления Покупателем расчетного документа (чека).</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>Ответственность сторон</b>
                                    <ol>
                                        <li>Стороны несут ответственность за невыполнение или ненадлежащее выполнение условий настоящего Договора в порядке, предусмотренном настоящим Договором и действующим законодательством ДНР.</li>
                                        <li>Стороны гарантируют, что обладают необходимой право- и дееспособностью, а также всеми правами и полномочиями, необходимыми и достаточными для заключения и исполнения настоящего Договора.</li>
                                        <li>Продавец не несет ответственности за измененный производителем внешний вид, комплектацию Товара.</li>
                                        <li>Продавец не несет ответственности за выбор Покупателем Товара, он лишь гарантирует соответствие заказанных позиций.</li>
                                        <li>Покупатель несет полную ответственность за предоставление неверных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем.</li>
                                        <li>В случае необоснованного заказа Покупателя Продавец вправе заблокировать доступ Покупателя к использованию сайта Продавца, заблокировать абонентский номер телефона Покупателя.</li>
                                        <li>Вся текстовая информация и графические изображения, размещенные на сайте Продавца, имеют законного правообладателя. Незаконное использование указанной информации и изображений преследуется в соответствии с действующим законодательством ДНР. Покупатель или любые другие третьи лица не имеют права использовать материалы, размещенные на сайте Продавца, а именно: вносить изменения, копировать, публиковать, передавать третьим лицам. Покупатель не должен использовать сайт Продавца в иных целях, кроме тех, которые указаны в настоящем Договоре.</li>
                                        <li>В случае обстоятельств непреодолимой силы, стороны освобождаются от выполнения условий настоящего Договора. Под обстоятельствами непреодолимой силы для целей настоящего Договора подразумеваются события, носящие чрезвычайный, неотвратимый непредсказуемый характер, исключающие или объективно препятствующие выполнению настоящего Договора, наступление которых Стороны не могли предвидеть и предотвратить их разумными мерами.</li>
                                        <li>В случае возникновения вопросов и претензий со стороны Покупателя необходимо заявить требование, направив его на соответствующий контактный адрес, или позвонив по телефону, указанному на сайте galaktika.me.</li>
                                    </ol>
                                    <p>Все споры, возникающие между Покупателем и Продавцом, решаются путем переговоров.</p>
                                </li>
                                <li>
                                    <b>Срок действия Договора</b>
                                    <ol>
                                        <li>Срок действия Договора не ограничен, если иное не указано на сайте Продавца.</li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose} className="">Закрыть</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default PrivPolicyModal;