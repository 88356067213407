
// React 
import React, { Component } from 'react';
import ProfileNav from './SideNav';
import { Button, Container, Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select'
//SCSS
import "../../scss/components/pages/profile/profile.scss"
import axios from "axios";

class PagePMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            goback: false,
            name: '',
            middle_name: '',
            surname: '',
            username: '',
            email: '',
            curPass: '',
            newPass: '',
            newPassConf: '',
            phones: '',
            city: '',
            street: '',
            home: '',
            flat: '',
            gender: '',
            date_birthday: '',
            pets: '',
            petsStr: '',
            hobbies: '',
            hobbiesStr: '',
            child: null,
            is_auto: false,
            vin_auto: '',
            phone: '',
            bd:'',
            bm:'',
            by:'',
            company_name: '',
            company_inn: '',
            address: [],
            error: false,
            error2: false,
            success: false,
            bdChanged:  false,
            avatar: '',
            cities: []
        }
        this.update = this.update.bind(this)
        this.changeInput = this.changeInput.bind(this)
        this.changeSelect = this.changeSelect.bind(this)
        this.changePassword = this.changePassword.bind(this)
        this.changePhones = this.changePhones.bind(this)
        this.changeAddress = this.changeAddress.bind(this)
        this.addPhone = this.addPhone.bind(this)
        this.addAddress = this.addAddress.bind(this)
        this.removePhone = this.removePhone.bind(this)
        this.removeAddress = this.removeAddress.bind(this)
        this.changeGender = this.changeGender.bind(this)
        this.changePets = this.changePets.bind(this)
        this.changeHobbies = this.changeHobbies.bind(this)
        this.changeChild = this.changeChild.bind(this)
        this.changeCar = this.changeCar.bind(this)
    }
    changeCar(e) {
        e.preventDefault()
        this.setState({
            is_auto: e.target.checked
        }, ()=>{
            console.log(this.state.is_auto);
        })
    }
    update(e) {
        e.preventDefault()
        this.setState({
            error2: false
        })
        if(this.state.newPass.length > 0 && (this.state.newPass !== this.state.newPassConf)) {
            this.setState({
                error2: true
            })
            return false
        }
        if(this.state.newPass.length > 0 && this.state.curPass.length === 0) return false

        let vin = ''
        if(this.state.is_auto) vin = 'VIN'
        if(this.state.vin_auto !== '') vin = this.state.vin_auto
        if(!this.state.is_auto) vin = ''

        const data = {
            oldpassword: this.state.curPass,
            password: this.state.newPass ? this.state.newPass : this.state.curPass,
            name: this.state.name,
            middle_name: this.state.middle_name,
            surname: this.state.surname,
            username: this.state.username,
            email: this.state.email,
            phones: this.state.phones.join(', '),
            city: this.state.city,
            street: this.state.street,
            home: this.state.home,
            flat: this.state.flat,
            detail_address: "",
            date_birthday: this.state.bdChanged ? (this.state.by + '-' + (this.state.bm*1+1) + '-' + this.state.bd) : null,
            gender: this.state.gender,
            child: this.state.child,
            vin_auto: vin,
            hobbies: this.state.hobbiesStr,
            pets: this.state.petsStr,
            company: null,
            addresses_json: JSON.stringify(this.state.address),
            company_name: this.state.company_name,
            company_inn: this.state.company_inn
        }
        // console.log(typeof data.hobbies, data.pets)
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile/update-user?jwt=${localStorage.getItem('galaxy-token')}`, data, {
            headers: { Authorization: "Bearer " + localStorage.getItem('galaxy-token') }
        }).then(response => {
            // console.log(response)
            this.setState({
                error: false,
                error2: false,
                success: true
            })
        }).catch(err => {
            console.error(err)
            this.setState({
                error: true,
                success: false
            })
        })
    }
    changePets(e) {
        let pets = this.state.pets
        let str = ''
        pets.forEach(pet => {
            if(pet.name === e.target.value) {
                pet.status = pet.status==='selected' ? 'no_selected' : 'selected'
            }
        })
        pets.forEach(pet => {
            if(pet.status ==='selected') {
                str += pet.name + ', '
            }
        })
        str = str.slice(0, -2);
        this.setState({pets: pets, petsStr: str})
    }
    changeHobbies(e) {
        let hobbies = this.state.hobbies
        let str = ''
        hobbies.forEach(hobby => {
            if(hobby.name === e.target.value) {
                hobby.status = hobby.status==='selected' ? 'no_selected' : 'selected'
            }
        })
        hobbies.forEach(pet => {
            if(pet.status ==='selected') {
                str += pet.name + ', '
            }
        })
        str = str.slice(0, -2);
        this.setState({hobbies: hobbies, hobbiesStr: str})
    }
    changeGender = (e) => {
        this.setState({
            gender: e.target.value
        })
    }
    changeChild = (e) => {
        this.setState({
            child: e.target.value === '1' ? 2 : null
        })
    }
    changeInput(e) {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changeSelect(e) {
        e.preventDefault()
        if(e.target.name === 'bd' || e.target.name === 'bm' || e.target.name === 'by') {
            this.setState({
                bdChanged: true
            })
        }
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changePassword(e) {
        e.preventDefault()
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    changePhones(e) {
        e.preventDefault()
        let n = e.target.name.replace('phone-','')
        let phones = this.state.phones
        phones[n] = e.target.value
        this.setState({
            phones: phones
        }, function(){
            // console.log(this.state.phones)
        })
    }
    getPets() {
        return this.state.pets.map((item, index) => {
            if(item.status !== 'no_selected') {
                return (
                    <span key={index}>
                        {index>0 ? ', '+item.name : item.name}
                    </span>
                )
            }
            else return false
        })
    }
    getHobbies() {
        return this.state.hobbies.map((item, index) => {
            if(item.status !== 'no_selected') {
                return (
                    <span key={index}>
                        {index>0 ? ', '+item.name : item.name}
                    </span>
                )
            } else return false
        })
    }
    getPhones() {
        return this.state.phones.map((item, index) => {
            return (
                <Form.Control key={index} type="text" name={'phone-' + index} value={item} />
            )
        })
    }
    addPhone(e) {
        e.preventDefault()
        let phones = this.state.phones
        phones.push('')
        this.setState({
            phones: phones
        })
    }
    removePhone(num) {
        let phones = this.state.phones
        phones.splice(num, 1)
        this.setState({
            phones: phones
        })
    }

    getAddress() {
        return this.state.phones.map((item, index) => {
            return (
                <Form.Control key={index} type="text" name={'phone-' + index} value={item} />
            )
        })
    }
    addAddress(e) {
        e.preventDefault()
        let addr = this.state.address || []
        addr.push({
            city: '',
            street: '',
            home: '',
            flat: ''
        })
        this.setState({
            address: addr
        })
    }
    removeAddress(num) {
        let addr = this.state.address
        addr.splice(num, 1)
        this.setState({
            address: addr
        })
    }
    changeAddress(e) {
        e.preventDefault()
        let addr = this.state.address
        let n
        if(e.target.name.indexOf('city') !== -1) {
            n = e.target.name.replace('city-','')
            addr[n].city = e.target.value
        }
        if(e.target.name.indexOf('street') !== -1) {
            n = e.target.name.replace('street-','')
            addr[n].street = e.target.value
        }
        if(e.target.name.indexOf('home') !== -1) {
            n = e.target.name.replace('home-','')
            addr[n].home = e.target.value
        }
        if(e.target.name.indexOf('flat') !== -1) {
            n = e.target.name.replace('flat-','')
            addr[n].flat = e.target.value
        }
        this.setState({
            address: addr
        })
    }
    changeFuckingCity(huy, pizda) {
        let addr = this.state.address
        addr[pizda].city = huy.value
        this.setState({
            address: addr
        })
    }
    clearFields(ffs) {
        ffs.map((f,i) => {
            return this.setState({
                [f]: ''
            })
        })
    }
    componentDidMount() {
        if(!localStorage.getItem('galaxy-token')) {
            this.setState({
                goback: true
            })
        }
        axios.get(`${process.env.REACT_APP_API_BASE}/pages/get-cities`).then(response => {
            this.setState({
                cities: response.data.data
            })
        })
        axios.post(`${process.env.REACT_APP_API_BASE}/cabinet/profile`, {
            jwt: localStorage.getItem('galaxy-token')
        }).then(response => {
            let dt;
            if(response.data.data.info.date_birthday) {
                dt = new Date(response.data.data.info.date_birthday)
            }
            let addressData = []
            if(response.data.data.info.addresses_json) {
                addressData = JSON.parse(JSON.parse(response.data.data.info.addresses_json))
            }
            // (JSON.parse(JSON.parse(response.data.data.info.addresses_json)).length>0) ? JSON.parse(JSON.parse(response.data.data.info.addresses_json)) : []
            this.setState({
                name: response.data.data.info.name || '',
                surname: response.data.data.info.surname || '',
                middle_name: response.data.data.info.middle_name || '',
                username: response.data.data.info.username || '',
                email: response.data.data.info.email || '',
                curPass: '',
                newPass: '',
                newPassConf: '',
                phones: response.data.data.info.phones,
                phone: response.data.data.info.phones || '',
                city: response.data.data.info.city || '',
                street: response.data.data.info.street || '',
                home: response.data.data.info.home || '',
                flat: response.data.data.info.flat || '',
                gender: response.data.data.info.gender || '',
                date_birthday: response.data.data.info.date_birthday,
                pets: response.data.data.pets,
                hobbies: response.data.data.hobbies,
                child: response.data.data.info.child,
                vin_auto: response.data.data.info.vin_auto || '',
                is_auto: response.data.data.info.vin_auto !== null,
                bd:(response.data.data.info.date_birthday) ? dt.getDate() : '',
                bm:(response.data.data.info.date_birthday) ? dt.getMonth() : '',
                by:(response.data.data.info.date_birthday) ? dt.getFullYear() : '',
                address: addressData,
                company_name:response.data.data.info.company_name || '',
                company_inn:response.data.data.info.company_inn || '',
                bdChanged: response.data.data.info.date_birthday!==null,
                avatar: response.data.data.info.icon_url || ''
            }, function(){
                let pets = this.state.pets
                let hobbies = this.state.hobbies
                let str1 = ''
                pets.forEach(pet => {
                    if(pet.status ==='selected') {
                        str1 += pet.name + ', '
                    }
                })
                str1 = str1.slice(0, -2);
                this.setState({petsStr: str1})
                let str2 = ''
                hobbies.forEach(pet => {
                    if(pet.status ==='selected') {
                        str2 += pet.name + ', '
                    }
                })
                str2 = str2.slice(0, -2);
                this.setState({hobbiesStr: str2})
            })
        }).catch(err => {
            console.error(err)
        })
    }

    render() {
        let d = new Date()
        const dates = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
        const month = ['январь','февраль','март','апрель','май','июнь','июль','август','сентябрь','октябрь','ноябрь','декабрь']
        let years = []
        for(let y=d.getFullYear()-15; y>=d.getFullYear()-100; y--) {
            years.push(y)
        }
        return (
            <main className='page-profile'>
                <Container>
                    <ProfileNav isEdit={true} />
                    <section className="prof-main">
                        <div className="breadcrumbs">
                            <div className="breadcrumbs__item">
                                <h1>Личный кабинет</h1>
                            </div>
                            <div className="breadcrumbs__item">
                                <h2>Профиль</h2>
                            </div>
                        </div>
                        {this.state.success &&
                            <Redirect to={{
                                pathname: "/profile",
                                state: { saved: true }
                            }} />
                        }
                        <Form onSubmit={this.update}>
                        <div className="prof-content pp-profile">
                            <div className="prof-content__header">
                                {/* <Button variant="outline-primary">Редактировать профиль</Button> */}
                                <Button type="submit" className="btn btn-primary">Сохранить</Button>
                                <Link to="/profile" className="btn btn-link">Отменить изменения</Link>
                            </div>
                            <div className="prof-content__body">
                                <div className="fields-wrap">
                                    <h5 className="title">Основные данные</h5>
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                            <Form.Label className="title">Логин</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="text"  name="username" value={this.state.username} onChange={this.changeInput} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                            <Form.Label className="title required">Имя</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="text" required name="name" value={this.state.name} onChange={this.changeInput} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                            <Form.Label className="title">Отчество</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="text" name="middle_name" value={this.state.middle_name} onChange={this.changeInput} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                            <Form.Label className="title required">Фамилия</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="text" required name="surname" value={this.state.surname} onChange={this.changeInput} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="002">
                                        <div className="form-field">
                                            <Form.Label className="title required">Email</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="email" required name="email" value={this.state.email} onChange={this.changeInput} />
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="003">
                                        <div className="form-field">
                                            <Form.Label className="title">Пароль</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="password" name="curPass" onChange={this.changeInput} className="m" placeholder="Старый / Временный пароль" />
                                                <Form.Control type="password" name="newPass" onChange={this.changeInput} className="m" placeholder="Новый пароль" />
                                                <Form.Control type="password" name="newPassConf" onChange={this.changeInput} className="m" placeholder="Подтвердите пароль" />
                                            </div>
                                        </div>
                                        {this.state.error &&
                                        <div className={'alert alert-danger'}>
                                            Старый пароль введён неверно. Попробуйте ещё раз
                                        </div>}
                                        {this.state.error2 &&
                                        <div className={'alert alert-danger'}>
                                            Пароли не совпадают. Попробуйте ещё раз
                                        </div>}
                                    </Form.Group>
                                    <Form.Group controlId="004">
                                        <div className="form-field">
                                            <Form.Label className="title">Телефон</Form.Label>

                                            <div className="form-field" style={{flexDirection:'column', minHeight: '45.75px'}}>
                                                {this.state.phones.length>0 &&
                                                    this.state.phones.map((el,i) => {
                                                        return (
                                                            <div className="input-field" key={i}>
                                                                <InputMask
                                                                    mask="(099)-999-9999"
                                                                    name={'phone-' + i}
                                                                    value={el}
                                                                    onChange={this.changePhones}
                                                                    className="form-control"/>

                                                                {/*<Form.Control*/}
                                                                {/*    type="text"*/}
                                                                {/*    name={'phone-' + i}*/}
                                                                {/*    value={el}*/}
                                                                {/*    onChange={this.changePhones}*/}
                                                                {/*    className="m"*/}
                                                                {/*    placeholder="Телефон"/>*/}
                                                                {i>=0 &&
                                                                    <Button variant="btn btn-danger btn-sm rounded-circle" onClick={() => {
                                                                        this.removePhone(i)
                                                                    }} style={{
                                                                        // height:'20px',
                                                                        // width:'20px',
                                                                        // lineHeight:'20px',
                                                                        // alignItems:'center',
                                                                        // justifyContent:'center',
                                                                        // display:'flex',
                                                                        // transform: 'translate(-5px,8px)'
                                                                    }}>
                                                                        <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                                                    </Button>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <Button variant="simple btn-sm rounded-circle btn-sm rounded-circle" onClick={this.addPhone}>
                                                <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                            </Button>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="005">
                                        <div className="form-field address-fields">
                                            <Form.Label className="title">Адрес</Form.Label>
                                            <div className="input-field">
                                                {/* <Form.Control type="text" name="city" className="field-city" placeholder="Город" value={this.state.city} onChange={this.changeInput} /> */}
                                                {this.state.cities.length>0 &&                                                
                                                    <Select 
                                                    className="field-s4"
                                                    placeholder={'Населённый пункт'}
                                                    options={this.state.cities.map(c => ({value: c, label:c})) } 
                                                    onChange={(e) => {this.setState({city: e.value})}}
                                                    value={this.state.cities.map(c => ({value: c, label:c})).find(huylo => huylo.value === this.state.city)}
                                                    />
                                                }

                                                
                                                <Form.Control type="text" name="street" placeholder="улица, проспект, бульвар, площадь" value={this.state.street} onChange={this.changeInput} />
                                                <Form.Control type="text" name="home" className="field-kv" placeholder="Дом" value={this.state.home} onChange={this.changeInput} />
                                                <Form.Control type="text" name="flat" className="field-kv" placeholder="кв." value={this.state.flat} onChange={this.changeInput} />
                                            </div>
                                            <Button variant="simple btn-sm rounded-circle btn-sm rounded-circle" onClick={this.addAddress}>
                                                <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                            </Button>
                                            <Button variant="btn btn-danger btn-sm rounded-circle" onClick={() => {
                                                this.clearFields(["city","street","home","flat"])
                                            }} style={{
                                            }}>
                                                <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                            </Button>
                                        </div>
                                        <div className="form-field flex-column without-label">
                                            {(this.state.address && this.state.address.length > 0) &&
                                                this.state.address.map((el,i) => {
                                                    return (
                                                        <div className="input-field mb-2" key={i}>
                                                            <div className="input-field">
                                                                {/* <Form.Control type="text" name={'city-'+i} value={this.state.address[i].city} onChange={this.changeAddress} className="field-city" placeholder="Город" /> */}
                                                                {this.state.cities.length>0 &&                                                
                                                                    <Select 
                                                                    placeholder={'Населённый пункт'}
                                                                    options={this.state.cities.map(c => ({value: c, label:c})) } 
                                                                    onChange={ (huy) => { this.changeFuckingCity(huy, i) } }
                                                                    value={this.state.cities.map(c => ({value: c, label:c})).find(huyloEbanoe => huyloEbanoe.value === this.state.address[i].city)}
                                                                    
                                                                    />
                                                                }
                                                                <Form.Control type="text" name={'street-'+i} value={this.state.address[i].street} onChange={this.changeAddress} placeholder="улица, проспект, бульвар, площадь" />
                                                                <Form.Control type="text" name={'home-'+i} value={this.state.address[i].home} onChange={this.changeAddress} className="field-kv" placeholder="Дом" />
                                                                <Form.Control type="text" name={'flat-'+i} value={this.state.address[i].flat} onChange={this.changeAddress} className="field-kv" placeholder="кв." />
                                                            </div>
                                                            <Button variant="btn btn-danger btn-sm rounded-circle" onClick={() => {
                                                                this.removeAddress(i)
                                                            }} style={{
                                                                // height:'20px',
                                                                // width:'20px',
                                                                // lineHeight:'20px',
                                                                // alignItems:'center',
                                                                // justifyContent:'center',
                                                                // display:'flex',
                                                                // transform: 'translate(-5px,3px)'
                                                            }}>
                                                                <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                                            </Button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="fields-wrap">
                                    <h5 className="title">Дополнительная информация</h5>
                                    <Form.Group controlId="006">
                                        <div className="form-field">
                                            <Form.Label className="title">Пол</Form.Label>
                                            <div className="input-field">
                                                <Form.Group className="checkout-payment__item">
                                                    <Form.Check
                                                        type="radio"
                                                        id="pol1"
                                                        value="m"
                                                        checked={this.state.gender === 'm'}
                                                        onChange={this.changeGender}
                                                        label='Мужской'
                                                    />
                                                </Form.Group>
                                                <Form.Group className="checkout-payment__item">
                                                    <Form.Check
                                                        type="radio"
                                                        id="pol2"
                                                        value="f"
                                                        checked={this.state.gender === 'f'}
                                                        onChange={this.changeGender}
                                                        label='Женский'
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="007">
                                        <div className="form-field">
                                            <Form.Label className="title">Дата рождения</Form.Label>
                                            <div className="input-field">
                                                <Form.Control as="select" name='bd' value={this.state.bd} onChange={this.changeSelect}>
                                                    {
                                                        dates.map((item,index) => {
                                                            return (
                                                                <option key={index} value={item}>
                                                                    {item}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                                <Form.Control as="select" className="month" name='bm' value={this.state.bm} onChange={this.changeSelect}>
                                                    {
                                                        month.map((item,index) => {
                                                            return (
                                                                <option key={index} value={index}>
                                                                    {item}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                                <Form.Control as="select" name='by' value={this.state.by} onChange={this.changeSelect}>
                                                    {
                                                        years.map((item,index) => {
                                                            return (
                                                                <option key={index} value={item}>
                                                                    {item}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="008">
                                        <div className="form-field">
                                            <Form.Label className="title">Мои питомцы</Form.Label>
                                            <div className="input-field">
                                                <div className="checkbox-wrap">
                                                    {this.state.pets.length>0 &&
                                                        this.state.pets.map( (item, index) => {
                                                            return (
                                                                <div className="checkbox-wrap__item" key={index}>
                                                                    <input className="custom-checkbox" type="checkbox" value={item.name}
                                                                           id={'pet-' + index} key={index} onChange={this.changePets} checked={item.status === 'selected'} />
                                                                    <label htmlFor={'pet-' + index}>{item.name}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="009">
                                        <div className="form-field">
                                            <Form.Label className="title">Мои интересы</Form.Label>
                                            <div className="input-field">
                                                <div className="checkbox-wrap">
                                                    {this.state.hobbies.length>0 &&
                                                    this.state.hobbies.map( (item, index) => {
                                                        return (
                                                            <div className="checkbox-wrap__item" key={index}>
                                                                <input className="custom-checkbox" type="checkbox" value={item.name}
                                                                       id={'hobby-' + index} key={index} onChange={this.changeHobbies} checked={item.status === 'selected'} />
                                                                <label htmlFor={'hobby-' + index}>{item.name}</label>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="010">
                                        <div className="form-field">
                                            <Form.Label className="title">Дети</Form.Label>
                                            <div className="input-field">
                                                <Form.Group className="checkout-payment__item">
                                                    <Form.Check
                                                        type="radio"
                                                        id="yes"
                                                        name="child"
                                                        value="1"
                                                        onChange={this.changeChild}
                                                        checked={this.state.child !== null}
                                                        label='Да' />
                                                </Form.Group>
                                                <Form.Group className="checkout-payment__item">
                                                    <Form.Check
                                                        type="radio"
                                                        id="no"
                                                        name="child"
                                                        value="2"
                                                        onChange={this.changeChild}
                                                        checked={this.state.child === null}
                                                        label='Нет'
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    {/*<Form.Group controlId="011">*/}
                                    {/*    <div className="form-field">*/}
                                    {/*        <Form.Label className="title">Автомобиль</Form.Label>*/}
                                    {/*        <div className="input-field">*/}
                                    {/*            <Form.Group className="checkout-payment__item">*/}
                                    {/*                <Form.Check*/}
                                    {/*                    type="radio"*/}
                                    {/*                    id="yes"*/}
                                    {/*                    name="automobil"*/}
                                    {/*                    value="1"*/}
                                    {/*                    onChange={this.changeInput}*/}
                                    {/*                    label='Да' />*/}
                                    {/*            </Form.Group>*/}
                                    {/*            <Form.Group className="checkout-payment__item">*/}
                                    {/*                <Form.Check*/}
                                    {/*                    type="radio"*/}
                                    {/*                    id="no"*/}
                                    {/*                    name="automobil"*/}
                                    {/*                    value="2"*/}
                                    {/*                    onChange={this.changeInput}*/}
                                    {/*                    label='нет'*/}
                                    {/*                />*/}
                                    {/*            </Form.Group>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group controlId="001">
                                        <div className="form-field">
                                            <Form.Label className="title">Автомобиль</Form.Label>
                                            <div className="input-field">
                                                <Form.Group className="checkout-payment__item">
                                                    {
                                                        this.state.is_auto &&
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="yes2"
                                                            name="car"
                                                            onChange={this.changeCar}
                                                            checked={true}
                                                            label='Да' />
                                                    }
                                                    {
                                                        !this.state.is_auto &&
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="yes2"
                                                            name="car"
                                                            onChange={this.changeCar}
                                                            checked={false}
                                                            label='Да' />
                                                    }
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {this.state.is_auto === 1 &&
                                            <div className="form-field">
                                                <Form.Label className="title">VIN Автомобиля</Form.Label>
                                                <div className="input-field">
                                                    <Form.Control type="text" name="vin_auto" value={this.state.vin_auto} onChange={this.changeInput} placeholder='' />
                                                    <Button variant="btn btn-danger btn-sm rounded-circle" onClick={() => {
                                                        this.clearFields(["vin_auto"])
                                                    }} style={{
                                                        // height:'20px',
                                                        // width:'20px',
                                                        // lineHeight:'20px',
                                                        // alignItems:'center',
                                                        // justifyContent:'center',
                                                        // display:'flex',
                                                        // transform: 'translate(-5px,3px)'
                                                    }}>
                                                        <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </Form.Group>
                                </div>
                                {/* <div className="fields-wrap">
                                    <h5 className="title">Юридическое лицо</h5>
                                    <Form.Group controlId="012">
                                        <div className="form-field">
                                            <Form.Label className="title">Полное название</Form.Label>
                                            <div className="input-field">
                                                <Form.Control type="text" name="company_name" value={this.state.company_name} onChange={this.changeInput} />
                                                <Button variant="btn btn-danger btn-sm rounded-circle" onClick={() => {
                                                this.clearFields(["company_name"])
                                                }} style={{
                                                    // height:'20px',
                                                    // width:'20px',
                                                    // lineHeight:'20px',
                                                    // alignItems:'center',
                                                    // justifyContent:'center',
                                                    // display:'flex',
                                                    // transform: 'translate(-5px,3px)'
                                                }}>
                                                    <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="013">
                                        <div className="form-field">
                                            <Form.Label className="title">Код ЕГРПОУ</Form.Label>
                                            <div className="input-field">
                                                <InputMask
                                                    mask="99999999"
                                                    name="company_inn"
                                                    value={this.state.company_inn}
                                                    onChange={this.changeInput}
                                                    className="form-control"/>
                                                <Button variant="btn btn-danger btn-sm rounded-circle" onClick={() => {
                                                    this.clearFields(["company_inn"])
                                                }}>
                                                    <svg className="w20"><use xlinkHref="#i_plus" /></svg>
                                                </Button>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </div> */}
                            </div>
                            <div className="prof-content__footer">
                                {/* <Button variant="outline-primary">Редактировать профиль</Button> */}
                                <Button type="submit" className="btn btn-primary">Сохранить</Button>
                                <Link to="/profile" className="btn btn-link">Отменить изменения</Link>
                            </div>
                        </div>
                        </Form>
                    </section>
                </Container>
            </main >
        )
    }
}

export default PagePMain;
