import React, { useContext, useEffect, useState } from 'react'
import CartCtx from '../CartCtx';
import axios from 'axios';
import { isIterableArray } from '../block-slider/utils';
import { NavLink } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import CartItem from './CartItem';

const CartHeader = () => {

    const { cart, getTotalCount, getTotalSumm, removeProduct } = useContext(CartCtx)

    const [isLoading, setIsLoading] = useState(true);

    const [products, setProducts] = useState([])


    const getProducts = () => {
        setIsLoading(true)
        const ids = cart.map(i => i.id)
        axios.post(`${process.env.REACT_APP_API_BASE}/products/products-for-cart`, { ids })
            .then(response => {
                if (isIterableArray(response.data.data)) {
                    const res = response.data.data.map(prod => ({
                        ...prod,
                        count_cart: cart.find(i => i.id == prod.id)?.count_cart
                    }))
                    setProducts(res)
                }
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        if (isIterableArray(cart)) getProducts()
    }, [cart?.length])

    const removeProductCart = (id) => {
        removeProduct(id)
        const filtered = products.filter(i => i.id !== id)
        setProducts(filtered)
    }

    return (
        <>
            <div className="h-cart">
                <NavLink to='/cart'>
                    <Button className="btn-cart" >
                        <i className="i-cart"></i>
                        <span className="cart-count">
                            {getTotalCount(products)}
                        </span>
                    </Button>
                </NavLink>
                <div className="popup-cart">
                    <div className="popup-cart__wrap">
                        <div className="popup-cart__header ">
                            <div className="flex items-center">
                                <p> Товаров в корзине: {getTotalCount(products)}</p>
                                <p>Всего: <span className="i-rub">{parseFloat((getTotalSumm(products) * 1).toFixed(2)).toLocaleString('ru')}</span></p>

                            </div>
                        </div>
                        {
                            isLoading ?
                                <div className='d-flex align-items-center justify-content-center py-5'>
                                    <Spinner animation='border' size='sm' />
                                </div>
                                :
                                <>
                                    <div className="cart-items">
                                        <div className="cart-items__wrap">
                                            {
                                                isIterableArray(products) &&
                                                products.map((product, index) => (
                                                    <CartItem
                                                        key={index}
                                                        product={product}
                                                        index={index}
                                                        isControl={false}
                                                        removeProductCart={removeProductCart}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="popup-cart__footer">
                                        <NavLink to="/checkout" className="btn btn-primary">Оформить заказ</NavLink>
                                        <NavLink to="/cart">
                                            <Button variant="link" className="open-cart" >Перейти в корзину</Button>
                                        </NavLink>
                                    </div>
                                </>
                        }

                    </div>
                </div>
            </div>
            {getTotalCount(products) === 0 &&
                <div className="cart_blocked" />
            }
        </>
    )
}

export default CartHeader